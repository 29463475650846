<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import RequestMaterialServices from '../Script/RequestMaterialServices.js';

export default {
    name: 'RequestMaterialGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['editClick','statusClick'],
    props: ['editClick'],
    computed: {
    },
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: 10,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                type : 'request_production_schedule_material',
                            }
                            return { 
                                query: RequestMaterialServices.readQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionMutation.production_mutation == null){
                            return [];
                        }else{
                            return response.data.GetProductionMutation.production_mutation;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionMutation.production_mutation == null){
                            return 0;
                        }else{
                            return response.data.GetProductionMutation.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "proddate"},
                            production_mutation_date: {type: "proddate"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    template:this.columnButton
                },
                { field: "production_mutation_code", title: "Kode Request Material", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes:{style: "text-align:center;"}
                },
                { field: "storage_id", title: "Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_code", title: "Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_schedule_code", title: "Kode Penjadwalan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_mutation_date", title: "Tanggal Material Dikirim", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes:{style: "text-align:center;"},
                    filterable: {ui: 'datepicker'} 
                },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(created_at, 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    attributes:{style: "text-align:center;"},
                    filterable: {ui: 'datepicker'} 
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
            
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        // var statusClick = this.$props.statusClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem.production_mutation_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_mutation_id, true);
        })
        
        // ////Status Form
        // GridElement.on("click", "#StatusButton", function (e){
        //     e.preventDefault();
        //     var dataItem = grid.dataItem(e.target.closest("tr"));

        //     statusClick(dataItem.production_mutation_id, dataItem.status);
        // })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithoutDeleteButton("Request Material")
        },
        detailInit: async function (e) {
            var detailData = await RequestMaterialServices.getRequestMaterialByIdQuery(e.data.production_mutation_type, e.data.production_mutation_id);

            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "requestMaterialGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(detailData.production_mutation_item);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: true,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    // { field: 'production_mutation_item_id', title: "ID Mutasi Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    // { field: 'item_id', title: "Item Id", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'base_uom', title: "Satuan Dasar", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_type', title: "Tipe Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'requested_quantity', title: "Request Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"}, editable: true, nullable: true },
                    { field: 'total_approved_quantity', title: "Approved Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"}, editable: true, nullable: true },
                ],
            })
        },
        changeStatus(status) {
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: 10,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                type : 'request_production_schedule_material',
                                status : status,
                            }
                            return { 
                                query: RequestMaterialServices.readQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionMutation.production_mutation == null){
                            return [];
                        }else{
                            return response.data.GetProductionMutation.production_mutation;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionMutation.production_mutation == null){
                            return 0;
                        }else{
                            return response.data.GetProductionMutation.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "proddate"},
                            production_mutation_date: {type: "proddate"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            })
        }
    },
    
}
</script>