<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Request Material</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>
                
                <!-- <request-material-grid ref="grid" :key="gridReload" :editClick="editClick" :statusClick="statusClick" /> -->
                <request-material-grid ref="grid" :key="gridReload" :editClick="editClick" />
                <request-material-status-form ref="RequestMaterialStatusModal" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import RequestMaterialGrid from '../RequestMaterial/Grid/RequestMaterialGrid.vue';
import RequestMaterialStatusForm from '../RequestMaterial/Component/RequestMaterialStatusForm.vue';

export default {
    name: 'RequestMaterial',
    components: {
        'request-material-grid': RequestMaterialGrid,
        'request-material-status-form': RequestMaterialStatusForm,
    },
    async mounted () {
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        editClick(id, view){
            const routeData = this.$router.resolve({ name: 'Request Material Form', params: {  formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        // statusClick(id, status) {
        //     // this.$refs.RequestMaterialStatusModal.statusClick(id, status);
        // },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
            })
        },
        reload(){
            this.gridReload++;
        },
        
    }
}
</script>

<style scoped>
</style>