<template>
    <div>
        <div class="modal fade" id="RequestMaterialItemModal" tabindex="-1" aria-labelledby="RequestMaterialItemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border border-primary">
                    <div class="modal-header">
                        <h4 id="RequestMaterialItemModalLabel" class="font-weight-bold">Quantity Approval History</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :columns="columns"
                            :resizable="true"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :editable="false"
                        >
                        </kendo-grid>
                        
                        <br>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import requestMaterialService from '../Script/RequestMaterialServices.js';

export default {
    name: 'RequestMaterialItemDetailForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { field: 'approved_by', title: "Approve Oleh", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'approved_date', title: "Tanggal Approve", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(approved_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    attributes:{style: "text-align:center;"},
                    filterable: {ui: 'datepicker'} 
                },
                { field: "quantity", title: "Quantity Approve", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes:{style: "text-align:right;"}
                }, 
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        viewClick(data){
            var MutationItemId = data;
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                id : MutationItemId
                            }
                            return { 
                                query: requestMaterialService.mutationItemDetailQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetProductionMutationItemDetail == null) {
                            return [];
                        }
                        else {
                            return response.data.GetProductionMutationItemDetail;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetProductionMutationItemDetail == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetProductionMutationItemDetail.lenght;
                        }
                    },                    
                    model: {
                        fields: {
                            approved_by: { type: "string", editable: false },
                            approved_date: { type: "date", editable: false },
                            quantity: { type: "number", editable: false },
                        }
                    }
                },
            });
            window.$('#RequestMaterialItemModal').modal('show');
        },
    }
}
</script>
