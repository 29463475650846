<template>
    <div>
        <div class="modal fade" id="RequestMaterialStatusModal" tabindex="-1" aria-labelledby="RequestMaterialStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="RequestMaterialStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProductionMutationId}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button-status" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import requestMaterialService from '../Script/RequestMaterialServices.js';

export default {
    name: 'RequestMaterialStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            StatusData : [],
            Status : '',
            ProductionMutationId: '',

            Error: 0,
        }
    },
    methods: {
        async statusClick(id, status){
            this.ModalTitle = 'Edit Status Request Material: ';

            this.ProductionMutationId = id;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_request_material');
            this.Status = status;
            
            window.$('#RequestMaterialStatusModal').modal('show');
        },
        async validation(itemDetailData) {
            var ItemGridData = itemDetailData;

            for(var i=0; i<ItemGridData.length; i++){
                if (ItemGridData[i].total_approved_quantity < ItemGridData[i].requested_quantity) {
                    this.$swal("Error", "Request Material tidak dapat di Approve karena Request Quantity pada Item " + ItemGridData[i].product_code 
                        + " masih lebih besar daripada Total Approve Quantity", "error");
                    this.Error++;
                    break;
                }
            }
        },
        async saveClick(){
            var data = await requestMaterialService.getRequestMaterialByIdQuery('request_production_schedule_material', this.ProductionMutationId);

            this.validation(data.production_mutation_item);

            if (this.Error == 0) {
                var ProductionMutationItemArray = this.$globalfunc.objectToArrayConverter(data.production_mutation_item, "RequestMaterial-SaveMutationItemGrid")

                const productionMutationData = {
                    production_mutation_type : data.production_mutation_type,
                    production_mutation_date : data.production_mutation_date,
                    status : this.Status,
                    production_id : data.production_id,
                    production_schedule_id : data.production_schedule_id,
                    Notes : data.notes,
                    items : ProductionMutationItemArray,
                }
                
                this.$loading(true);
                
                const variables = {
                    id: parseInt(this.ProductionMutationId),
                    data : productionMutationData
                }
                
                this.$swal(sweet_alert.update_status).then((result) => {
                    if (result.isConfirmed == true) {
                        requestMaterialService.editQuery(variables).then(res => {
                            window.$('#RequestMaterialStatusModal').modal('hide');
                            this.$swal("Info", response.successUpdate, "success");
                            this.$loading(false);
                            this.$props.reload();
                        }).catch(error => {
                            var errorMessage = this.$globalfunc.errorExtractor(error)
                            this.$swal("Error", errorMessage, "error");
                            this.$loading(false);
                        })
                    }
                })
            }
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>